import React, { useContext } from 'react'

import { StoreContext } from '~/store'

import { Box, Heading, Button, Spacer, Link } from '~/styles/components'
import { EmptyState } from './style'

import Drawer from '~/components/Drawer'

const Cart = ({ isOpen, onClose }) => {
  const { checkout, removeLineItem } = useContext(StoreContext)

  const isAnyLineItems = checkout.lineItems.length > 0

  console.log(checkout.totalPrice)

  return (
    <Drawer
      right
      width="600px"
      isOpen={isOpen}
      onClose={onClose}
    >
      {isAnyLineItems &&
        <Box
          display="flex"
          flexDirection="column"
          height="calc(100% - 3.5rem)"
        >
          <Box
            flex="1 1"
            overflowY="auto"
          >
            {checkout.lineItems.map(lineItem =>
              <CartItem
                {...lineItem}
                removeLineItem={removeLineItem}
              />
            )}
          </Box>
          <Spacer size="sm" />
          <Heading
            level="4"
            display="flex"
            justifyContent="flex-end"
          >
            Total: ${Number(checkout.totalPrice.amount).toFixed(2)}
          </Heading>
          <Spacer size="sm" />
          <Button
            bg="black"
            color="white"
            to={checkout.webUrl}
          >
            Checkout
          </Button>
        </Box>
      }
      {!isAnyLineItems &&
        <EmptyState>
          <Heading level="4">
            Your cart is empty
          </Heading>
        </EmptyState>
      }
    </Drawer>
  )
}

const CartItem = ({ variant, title, quantity, id, removeLineItem }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom="xs"
    >
      <Box
        as="img"
        width={{ _: 'md', sm: 'lg' }}
        marginRight="xs"
        src={variant.image.src}
        alt={title}
      />
      <Box
        display="flex"
        flex="1 1"
      >
        <Box flex="1 1" marginRight="xs">
          <Heading level="4">{title}</Heading>
          <Heading level="5">Size: {variant.title}</Heading>
          <Heading level="5">Qty: {quantity}</Heading>
          <Link
            typography="headingExtraSmall"
            underlined
            whiteSpace="nowrap"
            onClick={() => removeLineItem(id)}
          >
            Remove from cart
          </Link>
        </Box>
        <Box>
          <Heading level="4">
            ${(Number(variant.price.amount) * quantity).toFixed(2)}
          </Heading>
        </Box>
      </Box>
    </Box>
  )
}

export default Cart
