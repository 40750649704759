import space from './space'
import colors from './colors'
import typography from './typography'
import breakpoints from './breakpoints'
import flexboxgrid from './flexboxgrid'
import icons from './icons'

const theme = {
  space,
  sizes: space,
  colors,
  typography,
  breakpoints,
  flexboxgrid,
  icons
}

export default theme
