import { createGlobalStyle } from 'styled-components'

import { typographyStyle } from './utils'

export default createGlobalStyle`
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    color: black;
    ${typographyStyle('body')}
  }

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    margin-bottom: .75em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2 {
    ${typographyStyle('headingLarge')}
  }

  h3 {
    ${typographyStyle('headingMedium')}
  }

  h4 {
    ${typographyStyle('headingSmall')}
  }

  h5,
  h6 {
    ${typographyStyle('headingExtraSmall')}
  }

  a {
    color: currentColor;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: 150ms border ease-in-out;

    &:hover {
      border-bottom-color: currentColor;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  @keyframes infinite-carousel {
    from {
      transform: translateX(0);
    }
  }
`