import styled from 'styled-components'
import { boxSystem } from './box'

export const Paragraph = styled.p`
  ${boxSystem}
`

export const Heading = styled.h5.attrs(({ level }) => ({ as: `h${level || 5}` }))`
  ${boxSystem}
`
