import styled from 'styled-components'
import { boxSystem } from './box'
import {
  Row as FlexboxRow,
  Col as FlexboxCol
} from 'react-styled-flexboxgrid'

export const Container = styled.div.attrs(() => ({
  paddingX: { _: '1.5rem', md: '5rem' }
}))`
  max-width: 1500px;
  margin: 0 auto;
  overflow-x: hidden;
  ${boxSystem}
`

export const Row = styled(FlexboxRow)`
  ${boxSystem}
`
export const Col = styled(FlexboxCol)`
  max-width: 100%;
  ${boxSystem}
`
