import React from 'react'
import Client from 'shopify-buy'

const client = Client.buildClient({
  storefrontAccessToken: '41bc59b980837f7f67abba4546537023',
  domain: `matthewpeterkarak-com.myshopify.com`,
})

export const defaultStoreContext = {
  client,
  adding: false,
  menuOpen: false,
  cartOpen: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  getVariantAvailability: () => {},
  setMenuOpen: () => {},
  setCartOpen: () => {},
}

const StoreContext = React.createContext(defaultStoreContext)

export default StoreContext
