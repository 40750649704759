import styled, { css } from 'styled-components'
import {
  system,
  compose,
  color,
  background,
  space,
  layout,
  grid,
  typography,
  border,
  position,
  shadow,
  flexbox
} from 'styled-system'
import { typographyStyle } from '~/styles/utils'

export const boxSystem = css`
  ${compose(
    color,
    background,
    space,
    layout,
    grid,
    typography,
    border,
    position,
    shadow,
    flexbox,
    system({
      transition: true,
      transform: true,
      pointerEvents: true,
      visibility: true,
      whiteSpace: true
    })
  )}
  ${props => props.typography ? typographyStyle(props.typography) : ''}
`

export default styled.div`
  ${boxSystem}
`
