import React from 'react'
import { ThemeProvider } from 'styled-components'

import { useStore, StoreContext } from '~/store'

import Navigation from '~/components/Navigation'

import '~/styles/fonts.scss'
import GlobalStyle from '~/styles/global'
import theme from '~/styles/theme'

const Layout = ({ children, location }) => {
  const store = useStore()

  return (
    <ThemeProvider theme={theme}>
      <StoreContext.Provider value={store}>
        <>
          <GlobalStyle />
          <Navigation location={location} />
          {/* <BackgroundVideo location={location} /> */}
          <main>{children}</main>
        </>
      </StoreContext.Provider>
    </ThemeProvider>
  )
}

export default Layout
