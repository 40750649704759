// const fontFamily = `'HK Grotesk', Helvetica, sans-serif`
const fontFamily = `'Suisse', Helvetica, sans-serif`

const typography = {
  body: {
    default: {
      fontFamily,
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '150%'
    }
  },
  headingExtraSmall: {
    default: {
      fontFamily,
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '115%'
    }
  },
  headingSmall: {
    default: {
      fontFamily,
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '115%'
    }
  },
  headingMedium: {
    default: {
      fontFamily,
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '115%'
    }
  },
  headingLarge: {
    default: {
      fontFamily,
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '115%'
    }
  }
}

export default typography
