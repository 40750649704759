import React from 'react'

import { Box, Heading, Icon, Button } from '~/styles/components'

const Drawer = ({
  children,
  title,
  right,
  isOpen = false,
  width = '400px',
  onClose
}) => {
  return (
    <Box
      position="fixed"
      top="0"
      bottom="0"
      left="0"
      right="0"
      zIndex="200"
      visibility={isOpen ? 'visible' : 'hidden'}
      transition="visibility 500ms ease"
      pointerEvents="auto"
    >
      <Box
        position="absolute"
        zIndex="1"
        top="0"
        left="0"
        bottom="0"
        right="0"
        background="rgba(0, 0, 0, 0.6)"
        opacity={isOpen ? 1 : 0}
        transition="opacity 500ms ease"
        onClick={onClose}
      />
      <Box
        position="absolute"
        zIndex="2"
        top="0"
        bottom="0"
        left={right ? 'auto' : 0}
        right={right ? 0 : 'auto'}
        bg="white"
        color="black"
        width="calc(100% - 1rem)"
        maxWidth={width}
        padding="xs"
        transform={isOpen ? 'none' : `translateX(${right ? '' : '-'}100%)`}
        transition="transform 500ms ease"
      >
        <Box
          position="relative"
          height="100%"
        >
          <Button
            position="absolute"
            top="0"
            right="0"
            size="2.5rem"
            circle
            onClick={onClose}
          >
            <Icon
              name="cross"
              size="16px"
            />
          </Button>
          <Box
            height="2.5rem"
            display="flex"
            alignItems="center"
            marginBottom="xs"
          >
            <Heading level="4">
              {title}
            </Heading>
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Drawer
