import { useState, useEffect } from 'react'

import StoreContext, { defaultStoreContext } from './StoreContext'

const useStore = () => {
  const [store, setStore] = useState(defaultStoreContext)

  const initCheckout = async () => {
    const isBrowser = typeof window !== 'undefined'
    const existingCheckoutId = isBrowser
      ? localStorage.getItem('shopify_checkout_id')
      : null

    const setCheckout = checkout => {
      if (isBrowser)
        localStorage.setItem('shopify_checkout_id', checkout.id)

      setStore({ ...store, checkout })
    }

    if (existingCheckoutId) {
      try {
        const checkout = await store.client.checkout.fetch(existingCheckoutId)

        if (!checkout.completedAt) {
          setCheckout(checkout)
          return
        }
      } catch (e) {
        localStorage.setItem('shopify_checkout_id', null)
      }
    }

    const newCheckout = await store.client.checkout.create()
    setCheckout(newCheckout)
  }

  const addVariantToCart = (variantId, quantity) => {
    if (variantId === '' || !quantity) {
      console.error('Both a size and quantity are required.')
      return
    }

    setStore({ ...store, adding: true })

    const lineItemsToUpdate = [
      { variantId, quantity: parseInt(quantity, 10) },
    ]

    return store
      .client
      .checkout
      .addLineItems(store.checkout.id, lineItemsToUpdate)
      .then(checkout =>
        setStore({
          ...store,
          checkout,
          adding: false,
          cartOpen: true
        })
      )
  }

  const removeLineItem = (lineItemId) => {
    return store
      .client
      .checkout
      .removeLineItems(store.checkout.id, [lineItemId])
      .then(checkout =>
        setStore({ ...store, checkout })
      )
  }

  const updateLineItem = (lineItemId, quantity) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) }
    ]

    return store
      .client
      .checkout
      .updateLineItems(store.checkout.id, lineItemsToUpdate)
      .then(checkout =>
        setStore({ ...store, checkout })
      )
  }

  const getVariantAvailability = (productId, variantId) => {
    return new Promise((resolve, reject) => {
      store
        .client
        .product
        .fetch(productId)
        .then((product) => {
          const result = product.variants.filter(
            variant => variant.id === variantId
          )

          if (!result || !result[0]) {
            resolve(false)
            return
          }
          
          resolve(result[0].available)
        })
    })
  }

  const setMenuOpen = (menuOpen) => {
    setStore({ ...store, menuOpen })
  }

  const setCartOpen = (cartOpen) => {
    setStore({ ...store, cartOpen })
  }

  useEffect(() => {
    initCheckout()
  }, [])

  return {
    ...store,
    addVariantToCart,
    removeLineItem,
    updateLineItem,
    getVariantAvailability,
    setMenuOpen,
    setCartOpen
  }
}

export { useStore, StoreContext }
