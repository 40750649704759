
import CartIcon from '~/images/icons/cart.inline.svg'
import CrossIcon from '~/images/icons/cross.inline.svg'
import HamburgerIcon from '~/images/icons/hamburger.inline.svg'

const icons = {
  cart: CartIcon,
  cross: CrossIcon,
  hamburger: HamburgerIcon
}

export default icons
