import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { boxSystem } from './box'
import { buttonReset } from './button'

const linkStyle = css`
  transition: all 150ms ease-in-out;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  border-bottom: 1px solid ${props => props.underlined ? 'currentColor' : 'transparent'};

  &:hover {
    color: currentColor;
    border-bottom: 1px solid ${props => props.underlined && !props.disabled ? 'transparent' : 'currentColor'};
  }
`

const linkElement = ({ to }) => {
  if (!to) {
    return 'button'
  }
  if (to.startsWith('/')) {
    return Link
  }
  return 'a'
}

export default styled.a.attrs(props => ({
  as: linkElement(props),
  to: linkElement(props) === 'a' ? null : props.to,
  href: linkElement(props) === 'a' ? props.to : null
}))`
  ${buttonReset}
  ${props => props.plain ? '' : linkStyle}
  ${boxSystem}
`