import React, { useContext } from 'react'

import { StoreContext } from '~/store'

import Logo from '~/images/logo.inline.svg'

import { Box, Link, Button, Icon } from '~/styles/components'

import Cart from '~/components/Cart'


const navigationItems = [
  {
    name: 'Home',
    to: '/'
  },
  {
    name: 'Shop',
    to: '/shop'
  },
  {
    name: 'Contact',
    to: 'mailto:mpkmelbourne@gmail.com'
  }
]

const Navigation = ({ location }) => {
  const {
    cartOpen,
    setCartOpen
  } = useContext(StoreContext)

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="100"
      background="#b2b2b2"
      color="black"
      display="flex"
      padding="0 1rem"
      paddingTop="2px"
      fontSize="12px"
      fontWeight="400"
      lineHeight="1.2"
      height="20px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Cart
        isOpen={cartOpen}
        onClose={() => setCartOpen(false)}
      />
      <Box>
        <Link plain to="/">
          MPK Studio
        </Link>
      </Box>
      <Box>
        <Link
          plain
          to="https://rabbithole.mpk-studio.com"
          marginLeft="1rem"
        >
          Rabbit Hole
        </Link>
        <Link
          plain
          to="https://rabbithole.mpk-studio.com/info.html"
          marginLeft="1rem"
        >
          Info
        </Link>
        <Link
          plain
          onClick={() => setCartOpen(true)}
          marginLeft="1rem"
        >
          Cart
        </Link>
      </Box>
    </Box>
  )
}

export default Navigation
