import styled, { css } from 'styled-components'
import { color } from 'styled-system'
import { Link } from 'gatsby'
import { boxSystem } from './box'
import { typographyStyle } from '~/styles/utils'

export const buttonReset = css`
  margin: 0;
  padding: 0;
  overflow: visible;
  border: none;
  background: transparent;
  color: inherit;
  font: inherit;
  appearance: none;
  text-align: left;
  cursor: pointer;
  pointer-events: auto;
`

const defaultButtonStyle = css`
  ${buttonReset}
  color: black;
  background: white;
  padding: .75rem 1.5rem .75rem 1rem;
  width: ${props => props.wide ? '100%' : 'auto'};
  transition: opacity 150ms ease-in-out;
  text-align: center;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  opacity: ${props => props.disabled ? '0.5' : '1'};
  ${color}
  ${typographyStyle('headingMedium')}

  &:hover {
    opacity: 0.75;
  }
`

const outlineButtonStyle = css`
  ${defaultButtonStyle}
  border: 1px solid currentColor;
  background: transparent;
  position: relative;
  color: white;
  transition: all 150ms ease-in-out;
  ${color}

  &:hover {
    border: 1px solid ${props => props.color || 'white'}; /* Reset global anchor hover */
    color: ${props => props.color === 'black' ? 'white' : 'black'};
    background: ${props => props.color || 'white'};
    opacity: 1;
  }
`

const circleButtonStyle = css`
  ${buttonReset}
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background: white;
  border-radius: 50%;
  transition: opacity 150ms ease-in-out;
  font-weight: bold;
  ${color}

  &:hover {
    opacity: 0.75;
  }
`

const buttonStyle = ({ circle, outline }) => {
  if (outline) {
    return outlineButtonStyle
  }
  if (circle) {
    return circleButtonStyle
  }
  return defaultButtonStyle
}

const buttonElement = ({ to }) => {
  if (!to) {
    return 'button'
  }
  if (to.startsWith('/')) {
    return Link
  }
  return 'a'
}

export default styled.button.attrs(props => ({
  as: buttonElement(props),
  to: buttonElement(props) === 'a' ? null : props.to,
  href: buttonElement(props) === 'a' ? props.to : null
}))`
  ${buttonStyle}
  ${boxSystem}
`
